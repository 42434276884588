.plusSign,
.addItemsWrapper,
.eachAddMealButton,
.mainNavBar,
.navAddButtonContainer {
  position: absolute;
}

.navAddButtonContainer,
.addItemsWrapper {
  display: flex;
}

.mainNavBar,
.addItemsWrapper {
  width: 100%;
}

.mainNavBar {
  background: none;
  height: 100%;
  width: 100vw;
  z-index: 0;
  top: 0;
  left: 0;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.mainNavBar.active {
  background: rgba(51, 51, 51, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1;
}

.addButton {
  z-index: 2;
  cursor: pointer;
}

.plusSign {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  transition: 0.5s ease-out;
}

.plusSign.active {
  transform: translate(-50%, -50%) rotate(225deg);
  transform-origin: center;
}

.navAddButtonContainer.first {
  top: 66%;
}

.navAddButtonContainer {
  height: fit-content;
  justify-content: space-around;
  font-weight: 500;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.addItemsWrapper {
  height: fit-content;
  bottom: 95px;
  z-index: -1;
  justify-content: center;
}

.addItemsWrapper.first {
  top: 65%;
}

.eachAddMealButton {
  left: 50%;
  transform: translateX(-50%) scale(0.65);
  text-align: center;
  transition: .4s ease-out;
  cursor: pointer;
}

.mealName {
  margin-top: -48px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #005A36;
}

.eachAddMealButton div:last-child {
  opacity: 0;
}

.eachAddMealButton.active div:last-child {
  opacity: 1;
}

.eachAddMealButton.active:first-child {
  transform: translate(-200%, -78px);
}

.eachAddMealButton.active:last-child {
  transform: translate(100%, -78px);
}

.eachAddMealButton.active:nth-child(2) {
  transform: translate(-115%, -170px);
}

.eachAddMealButton.active:nth-child(3) {
  transform: translate(15%, -170px);
}
