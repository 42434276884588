.leafLogo,
.hamburgerIcon {
  position: absolute;
  z-index: 2;
}

.leafLogo {
  width: 64px;
  height: 64px;
  top: 20px;
  left: 15px;
}

.hamburgerIcon {
  width: 30px;
  height: 20px;
  top: 28px;
  right: 19px;
}

.headerDropdownMenu {
  background-color: white;
  display: none;
  position: absolute;
  color: #979797;
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  z-index: 1;
  width: 100%;
}

.headerDropdownMenu.active {
  display: block;
}

.closeMenuIcon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.headerDropdownMenuItem {
  padding: 0 15px 24px;
}
