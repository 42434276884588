@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
/** CSS RESET START **/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/** CSS RESET END **/
* {
  box-sizing: border-box;
  /* border: 1px red solid; */
}

body {
  background: #16B187;
  margin: 0;
  font-family: Ubuntu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 100%;
  overflow-y: hidden;
}

.page-container {
  width: 85%;
  margin: 80px auto 85px auto;
}

a {
  color: inherit;
  text-decoration: none;
}

.heading {
  margin: 20px 0;
  text-align: center;
}

.heading h1 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #005A36;
}

.secondary-heading {
  height: 40px;
  color: #FFFFFF;
}

.secondary-heading h2 {
  font-size: 20px;
  line-height: 125.2%;
}

.page-content {
  margin-top: 20px;
  background-color: #F8F8FC;
  padding: 20px;
}

.page-content.full-page, .page-content.full-page.search {
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: -70px;
  height: calc(100% - 138px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 85px;
  overflow-y: auto;
}
.page-content.full-page {
  height: calc(100% - 68px);
}
.page-content.full-page.search {
  height: calc(100% - 135px);
}
sub {
    font-size: 60%;
}

.DashboardBarometer_barometerContainer__3b14o {
    height: 430px;
    border-radius: 11px;
    position: relative;
    z-index: 1;
}

.DashboardBarometer_repeat__Bu6K6 {
    position: absolute;
    top: 20px;
    right: 20px;
}

.DashboardBarometer_header__32s-p h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
}
.DashboardBarometer_header__32s-p div, .DashboardBarometer_type__10ziA {
    font-size: 16px;
    line-height: 125.2%;
    color: #979797;
}

.DashboardBarometer_barometerContainer__3b14o>.DashboardBarometer_header__32s-p div {
    text-decoration: underline;
    text-underline-offset: 6px;
    margin: 2px 0;
}

.DashboardBarometer_barometerSection__1ho9w .DashboardBarometer_header__32s-p {
    padding: 15px 0 6px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5;
}

.DashboardBarometer_barometerSection__1ho9w .DashboardBarometer_header__32s-p div span {
    font-weight: 700;
}

.DashboardBarometer_nutritionFacts__2xXaz {
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.DashboardBarometer_climateNote__30rey {
    text-align: center;
    width: 65%;
    margin: 0 auto;
}

.DashboardBarometer_barometer__2FZcz {
    line-height: 29px;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
}

.DashboardBarometer_barometerContent__3yIHR {
    width: 100%;
}

.DashboardBarometer_barometer__2FZcz .DashboardBarometer_small__2IAfY {
    font-weight: normal;
    font-size: 16px;
    line-height: 125.2%;
    color: #979797;
}

.DashboardBarometer_barometer__2FZcz, .DashboardBarometer_value__14per {
    font-size: 24px;
}

.DashboardBarometer_limit__1ryGx, .DashboardBarometer_value__14per {
    font-weight: 700;
}
.DashboardBarometer_value__14per {
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: #282C35;
}

.DashboardBarometer_footer__KAUDR {
    position: absolute;
    bottom: 20px;
    color: #979797;
}

.DashboardBarometer_progressBar__32IRf {
    height: 15px;
    border-radius: 50px;
    background: #E3E8EB;
    margin-bottom: 15px;
}

.DashboardBarometer_progressBar__32IRf .DashboardBarometer_meter__2BSnl {
    height: 15px;
    border-radius: 50px;
    background: linear-gradient(90deg, yellow, orange, orangered);
}

.Footer_plusSign__9WxEu,
.Footer_addItemsWrapper__w84wu,
.Footer_eachAddMealButton__100_-,
.Footer_mainNavBar__3DlNz,
.Footer_navAddButtonContainer__2tXmA {
  position: absolute;
}

.Footer_navAddButtonContainer__2tXmA,
.Footer_addItemsWrapper__w84wu {
  display: flex;
}

.Footer_mainNavBar__3DlNz,
.Footer_addItemsWrapper__w84wu {
  width: 100%;
}

.Footer_mainNavBar__3DlNz {
  background: none;
  height: 100%;
  width: 100vw;
  z-index: 0;
  top: 0;
  left: 0;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.Footer_mainNavBar__3DlNz.Footer_active__178eD {
  background: rgba(51, 51, 51, 0.5);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 1;
}

.Footer_addButton__dH9Uz {
  z-index: 2;
  cursor: pointer;
}

.Footer_plusSign__9WxEu {
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  transition: 0.5s ease-out;
}

.Footer_plusSign__9WxEu.Footer_active__178eD {
  transform: translate(-50%, -50%) rotate(225deg);
  transform-origin: center;
}

.Footer_navAddButtonContainer__2tXmA.Footer_first__3abpT {
  top: 66%;
}

.Footer_navAddButtonContainer__2tXmA {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-around;
  font-weight: 500;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.Footer_addItemsWrapper__w84wu {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  bottom: 95px;
  z-index: -1;
  justify-content: center;
}

.Footer_addItemsWrapper__w84wu.Footer_first__3abpT {
  top: 65%;
}

.Footer_eachAddMealButton__100_- {
  left: 50%;
  transform: translateX(-50%) scale(0.65);
  text-align: center;
  transition: .4s ease-out;
  cursor: pointer;
}

.Footer_mealName__2ZieX {
  margin-top: -48px;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #005A36;
}

.Footer_eachAddMealButton__100_- div:last-child {
  opacity: 0;
}

.Footer_eachAddMealButton__100_-.Footer_active__178eD div:last-child {
  opacity: 1;
}

.Footer_eachAddMealButton__100_-.Footer_active__178eD:first-child {
  transform: translate(-200%, -78px);
}

.Footer_eachAddMealButton__100_-.Footer_active__178eD:last-child {
  transform: translate(100%, -78px);
}

.Footer_eachAddMealButton__100_-.Footer_active__178eD:nth-child(2) {
  transform: translate(-115%, -170px);
}

.Footer_eachAddMealButton__100_-.Footer_active__178eD:nth-child(3) {
  transform: translate(15%, -170px);
}

.Landing_heading__2hjIV {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36.77px;
}

.Landing_darkGreenText__10-wC {
  color: #005A36;
}

.Landing_welcomeHeading__335lN {
  padding-bottom: 12px;
}

.Landing_welcomeMsg__1htLC {
  padding-bottom: 65px;
  font-size: 20px;
  line-height: 25.04px;
}

.Landing_signIn__3Kpkx {
  margin: 30px 0;
  font-size: 16px;
  line-height: 20.03px;
}

.Landing_signInLink__2iaTf {
  text-decoration: underline;
  text-underline-offset: 6px;
}

.Landing_feet__1zSv9 {
  margin-top: 140px;
  line-height: 14px;
  color: #B4FCE8;
}

#Landing_toremove__33wVj {
  position: absolute;
  bottom: 30px;
}
.ChooseMeal_results__2H2Y2 {
  padding: 20px 10px;
}

.ChooseMeal_heading__1QTUu {
  margin-bottom: 45px;
}

.ChooseMeal_heading__1QTUu h1 {
  font-weight: bold;
  font-size: 20px;
}

.ChooseMeal_search__3-L8C {
  margin: 15px auto;
}

.ChooseMeal_search__3-L8C input {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAABhWlDQ1BJQ0MgcHJvZmlsZQAAKJF9kT1Iw1AUhU/TiiIVBwuKOGSoThakiugmVSyChdJWaNXB5KV/0KQhSXFxFFwLDv4sVh1cnHV1cBUEwR8QNzcnRRcp8b6k0CLGC4/3cd49h/fuA4RGhalmYAJQNctIxWNiNrcqdr/Ch0EEMIuoxEw9kV7MwLO+7qmb6i7Cs7z7/qw+JW8ywCcSzzHdsIg3iKc3LZ3zPnGIlSSF+Jx43KALEj9yXXb5jXPRYYFnhoxMap44RCwWO1juYFYyVOIp4rCiapQvZF1WOG9xVis11ronf2Ewr62kuU5rBHEsIYEkRMiooYwKLERo10gxkaLzmId/2PEnySWTqwxGjgVUoUJy/OB/8Hu2ZmEy6iYFY0DXi21/jALdu0Czbtvfx7bdPAH8z8CV1vZXG8DMJ+n1thY+Avq3gYvrtibvAZc7wNCTLhmSI/lpCYUC8H5G35QDBm6B3jV3bq1znD4AGZrV8g1wcAiMFSl73ePdPZ1z+7enNb8f3bZy0kRmq5wAAAAGYktHRAD/AP8A/6C9p5MAAAAJcEhZcwAADdcAAA3XAUIom3gAAAAHdElNRQflARcFAwkuyU+LAAACOElEQVQ4y6WUT0sVURjGf+85ibMQu0VFkK0q25TcmTsytLhqibuEQLIPILbKj+CnsFWCrf0DLYxWKmhJXYYZRFpE/xZFBRpJtUg8MqfNuZfbda5181m9nMP5zfO875kj5CgMwyFr7ShQBrrc8kfgqVJqLo7jJZpIGkCXrbXTDtRU1trVLMvGNzY23jTuqWrR29vbb6194WBbwKRSyjfGdBhjOkQkACaBbRHp11pXgiAo5zp0zp4DJ4B5z/PG1tfXf+a5i6Ko0xgzIyIjwDdrbZSm6dvq/jEXYboKS5LkDmCbxa1UKj+A20EQzIvIiIhMA9drkcMwHKrG9Dxv7DBYfRtFZAzYBgZ83x+sAd00AaaaxcxTkiTfgfsAWuvR+qGUAbIsW6RFiciia1lfPfAcwN7e3rtWge3t7dVhdB24NoVCQVoF7u/vV89k9cBPzuGF/wBedOXneuCa6+Fwq0Br7bDr5VoNqJSac/VEFEWd/worFosFEbnnzMzWgHEcL1lrV4HTxpiZxv+7iZTW+iFwClhJ03Tlj6FkWTYO7IjISBiGC6VS6fhhzkql0gJwC9gB7ua+Nr7v9ymlHgEnga/AFPB4d3f3NYDned3ATWDCOQN4b4y5trm5uZX7fBWLxUta6wfAwF8irwFngW7gpTFmsArN7Zfv+4Na61FrbRk475Y/iMizLMtm0zRd8X3/tFJqGbgKvAJuJEnypeXL3PDhA9AjAQF6enrOtLW1LQNXrLXxkYF10CfAr9+AFtbU7CRm+gAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-position: right calc(.375em + .44rem) center;
  background-size: calc(.6em + .35rem) calc(.6em + .35rem);
}

.ChooseMeal_tabs__hh_PG {
  text-align: center;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  color: #979797;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.015em;
}
.ChooseMeal_tabs__hh_PG div:first-child {
  flex-grow: 0.5;
}

.ChooseMeal_tab__2_I_D  {
  padding-bottom: 9px;
}

.ChooseMeal_tab__2_I_D.ChooseMeal_tabActive__jz6gI {
  color: #16B187;
  border-bottom: 2px solid #16B187;
}

.ChooseMeal_addNewMealOption__3Vc0U {
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
  text-underline-offset: 6px;
  color: #16B187;
  padding-bottom: 85px;
}

.ChooseMeal_button__1QAwz {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.ChooseMeal_button__1QAwz>div {
  margin: 0 auto;
}

svg path {
    transition: 0.5s;
}

.SearchResults_favourite__1O9xM svg path {
    fill: red;
}
.SuccessfulAdd_active__2C0QZ {
    bottom: 50% !important;
    transform: translateY(60%);
}

input {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 14px;
    /* height: 58px; */
    border: none;
    border-radius: 999px;
}

input.InputBar_edit__ugogN {
    width: 60px;
    text-align: right;
    display: inline;
    margin-top: -10px;
    margin-bottom: -13px;
    margin-right: 3px;
    height: 90%;
    border-bottom: 1px #16B187 solid;
}
.CreateFood_mealInfo__2uAef h3, .CreateFood_ingredients__IiggT h4 {
    font-size: 20px;
}

.CreateFood_mealInfo__2uAef h3 {
    font-weight: 700;
    line-height: 125.2%;
    color: #16B187;
    margin: 10px 0;
    text-transform: uppercase;
}

.CreateFood_ingredients__IiggT {
    /* background-color: white; */
    overflow-y: auto;
    margin: 20px 0;
    transition: 0.9s cubic-bezier(1, 0.3, 0.3, 1);
    transform: translateY(0);
}

.CreateFood_ingredients__IiggT h4, .CreateFood_ingredientName__1HI7a {
    font-weight: 500;
}

.CreateFood_ingredients__IiggT h4 {
    line-height: 18px;
    color: #282C35;
}

.CreateFood_ingredients__IiggT h4, .CreateFood_ingredient__2P6jq {
    padding: 10px 20px;
}

.CreateFood_ingredients__IiggT hr {
    border: 1px solid #16B187;
}

.CreateFood_ingredient__2P6jq {
    display: flex;
    font-size: 16px;
    line-height: 125.2%;
    justify-content: space-between;
}

.CreateFood_ingredientWeight__1zok5 {
    color: #979797;
}

.CreateFood_button__1dmdL {
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}
.Header_leafLogo__NDciA,
.Header_hamburgerIcon__3xZ61 {
  position: absolute;
  z-index: 2;
}

.Header_leafLogo__NDciA {
  width: 64px;
  height: 64px;
  top: 20px;
  left: 15px;
}

.Header_hamburgerIcon__3xZ61 {
  width: 30px;
  height: 20px;
  top: 28px;
  right: 19px;
}

.Header_headerDropdownMenu__2VHWc {
  background-color: white;
  display: none;
  position: absolute;
  color: #979797;
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  z-index: 1;
  width: 100%;
}

.Header_headerDropdownMenu__2VHWc.Header_active__3O2Zj {
  display: block;
}

.Header_closeMenuIcon__1jtRO {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.Header_headerDropdownMenuItem__2qhPP {
  padding: 0 15px 24px;
}

.Meal_mealHeading__3PRzo {
  margin: 5px 0;
}

.Meal_mealInfo__2Qcqu h3, .Meal_ingredients__xiZv2 h4 {
    font-size: 20px;
}

.Meal_mealInfo__2Qcqu h3 {
  font-weight: 700;
  line-height: 125.2%;
  color: #16B187;
  margin: 10px 0;
  text-transform: uppercase;
}

.Meal_mealInfo__2Qcqu>div {
    background-color: white;
    height: 128px;
    border-radius: 10px;
}

.Meal_infoContainer__2eqDw, .Meal_ingredient__7D-Rq {
    display: flex;
}

.Meal_infoContainer__2eqDw {
    align-items: center;
}

img {
    height: 128px;
    width: auto;
}

.Meal_ingredients__xiZv2 {
    background-color: white;
    margin: 20px 0;
    transition: 0.9s cubic-bezier(1, 0.3, 0.3, 1);
    transform: translateY(0);
    overflow-y: auto;
}

.Meal_ingredients__xiZv2.Meal_edit__2QCaC {
    transform: translateY(-180px);
}

.Meal_ingredients__xiZv2 h4, .Meal_ingredientName__uPlnI {
    font-weight: 500;
}

.Meal_ingredients__xiZv2 h4 {
    line-height: 18px;
    color: #282C35;
}

.Meal_ingredients__xiZv2 h4, .Meal_ingredient__7D-Rq {
    padding: 10px 20px;
}

.Meal_ingredients__xiZv2 hr {
    border: 1px solid #16B187;
}

.Meal_ingredient__7D-Rq {
    font-size: 16px;
    line-height: 125.2%;
    justify-content: space-between;
}

.Meal_ingredientWeight__2q7Aq {
    color: #979797;
}

.Meal_button__rVWAa {
    position: fixed;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
}

.Meal_button__rVWAa>div {
    margin: 0 auto;
}

.Meal_infoContainer__2eqDw>div:first-child {
    flex-grow: 1;
}
.Meal_infoContainer__2eqDw>div:last-child {
    flex-grow: 1;
}
.Diary_date__3BY-- {
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
}

.Diary_heading__1WJXL {
    margin: 5px 0;
}

.Diary_bold__3gRuv {
    font-weight: 700;
}

.Diary_textCenter__1ksku {
    text-align: center;
}

.Diary_dayInfo__gG238 {
    margin: 20px 0 25px 0;
}

.Diary_dayInfo__gG238 h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 125.2%;
    color: #16B187;
    margin: 10px 0;
    text-transform: uppercase;
}

.Diary_calories__1A8s8 {
    margin: 25px 0;
    color: #979797;
}

.Diary_calories__1A8s8 h1 {
    color: #282C35;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    margin-bottom: 8px;
}

.Diary_nutrition__1Hte0 {
    display: flex;
    justify-content: space-around;
}
.Diary_nutrition__1Hte0 div:first-child {
    color: #282C35;
    line-height: 125.2%;
}
.Diary_nutrition__1Hte0 div:last-child {
    color: #979797;
    line-height: 125.2%;
}

.Diary_meal__2Itr2 {
    display: flex;
    margin: 14px 0
}

.Diary_meal__2Itr2>div:first-child {
    line-height: 125.2%;
    color: #232323;
    font-weight: 600;
    width: 30%;
    padding-top: 7px;
}

.Diary_meal__2Itr2>div:last-child {
    border-radius: 10px;
    background-color: #E6E7EB;
    padding: 16px 24px;
    width: 70%;
}

.Diary_addFood__2hXSe {
    color: #16B187;
    line-height: 125.2%;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.Diary_eachMeal__d56D8{
    line-height: 125.2%;
    color: #000000;
}
