input {
    width: 100%;
    display: block;
    font-size: 16px;
    padding: 14px;
    /* height: 58px; */
    border: none;
    border-radius: 999px;
}

input.edit {
    width: 60px;
    text-align: right;
    display: inline;
    margin-top: -10px;
    margin-bottom: -13px;
    margin-right: 3px;
    height: 90%;
    border-bottom: 1px #16B187 solid;
}