@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

/** CSS RESET START **/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/** CSS RESET END **/
* {
  box-sizing: border-box;
  /* border: 1px red solid; */
}

body {
  background: #16B187;
  margin: 0;
  font-family: Ubuntu;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  height: 100%;
  overflow-y: hidden;
}

.page-container {
  width: 85%;
  margin: 80px auto 85px auto;
}

a {
  color: inherit;
  text-decoration: none;
}

.heading {
  margin: 20px 0;
  text-align: center;
}

.heading h1 {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.015em;
  text-align: left;
  color: #005A36;
}

.secondary-heading {
  height: 40px;
  color: #FFFFFF;
}

.secondary-heading h2 {
  font-size: 20px;
  line-height: 125.2%;
}

.page-content {
  margin-top: 20px;
  background-color: #F8F8FC;
  padding: 20px;
}

.page-content.full-page, .page-content.full-page.search {
  width: 100vw;
  position: absolute;
  left: 0;
  bottom: -70px;
  height: calc(100% - 138px);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding-bottom: 85px;
  overflow-y: auto;
}
.page-content.full-page {
  height: calc(100% - 68px);
}
.page-content.full-page.search {
  height: calc(100% - 135px);
}