.results {
  padding: 20px 10px;
}

.heading {
  margin-bottom: 45px;
}

.heading h1 {
  font-weight: bold;
  font-size: 20px;
}

.search {
  margin: 15px auto;
}

.search input {
  background-image: url('../assets/search.png');
  background-repeat: no-repeat;
  background-position: right calc(.375em + .44rem) center;
  background-size: calc(.6em + .35rem) calc(.6em + .35rem);
}

.tabs {
  text-align: center;
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  color: #979797;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.015em;
}
.tabs div:first-child {
  flex-grow: 0.5;
}

.tab  {
  padding-bottom: 9px;
}

.tab.tabActive {
  color: #16B187;
  border-bottom: 2px solid #16B187;
}

.addNewMealOption {
  font-size: 16px;
  text-align: right;
  text-decoration: underline;
  text-underline-offset: 6px;
  color: #16B187;
  padding-bottom: 85px;
}

.button {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
}

.button>div {
  margin: 0 auto;
}
