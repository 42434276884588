.heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36.77px;
}

.darkGreenText {
  color: #005A36;
}

.welcomeHeading {
  padding-bottom: 12px;
}

.welcomeMsg {
  padding-bottom: 65px;
  font-size: 20px;
  line-height: 25.04px;
}

.signIn {
  margin: 30px 0;
  font-size: 16px;
  line-height: 20.03px;
}

.signInLink {
  text-decoration: underline;
  text-underline-offset: 6px;
}

.feet {
  margin-top: 140px;
  line-height: 14px;
  color: #B4FCE8;
}

#toremove {
  position: absolute;
  bottom: 30px;
}